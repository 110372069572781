/* global $crisp */
import React from 'react';
import get from 'lodash/get';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from '@utils/localize';
import { useGoal } from 'gatsby-plugin-fathom';

import colors from '@utils/colors';
import getLocalizedData from '@utils/localize';
import Container from '@components/Container';
import Image from '@components/Image';
import Link from '@components/Link';
import { Heading } from '@components/type';
import * as styled from './styles';
import Logo from '../Logo';
import SocialLink from '../SocialLink';
import { Body } from '../type';

function Footer({ showChat }) {
  const rawData = useStaticQuery(graphql`
    query FooterQuery {
      sanityFooter {
        chatHeading {
          en
          vi
          zh_Hans
          _type
        }
        chatFathomGoalId
        copyright {
          en
          vi
          zh_Hans
          _type
        }
        description {
          en
          vi
          zh_Hans
          _type
        }
        imagesExtended {
          image {
            asset {
              fluid(maxWidth: 400, sizes: "(max-width: 600px) 10vw, 10vw") {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        primaryLinks {
          title {
            en
            vi
            zh_Hans
            _type
          }
          slug {
            current
          }
        }
        secondaryLinks {
          title {
            en
            vi
            zh_Hans
            _type
          }
          slug {
            current
          }
        }
        enquireLink {
          isHidden
          theme
          title {
            en
            vi
            zh_Hans
          }
          Link {
            slug {
              current
            }
          }
        }
      }
    }
  `);

  const intl = useIntl();
  const data = getLocalizedData(rawData, intl.locale);

  const {
    chatHeading,
    copyright,
    description,
    imagesExtended,
    primaryLinks,
    secondaryLinks,
    chatFathomGoalId,
    enquireLink,
  } = data.sanityFooter;

  return (
    <styled.Wrapper>
      {showChat && (
        <>
          <styled.WavyBorder>
            <svg
              width='1062'
              height='16'
              viewBox='0 0 1062 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M2 8.00007C2 8.00007 450 22.2572 682 8.00007C914 -6.25704 1060 8.00007 1060 8.00007'
                stroke={colors.B100}
                strokeWidth='3'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </styled.WavyBorder>
          <Container centered columns={[12, 8]} noPadding>
            <styled.Chat>
              {get(imagesExtended, 'length') && (
                <styled.ImagesWrapper>
                  {get(imagesExtended, '[0].image.asset.fluid') && (
                    <styled.ImageWrapper>
                      <Image edge={0} fluid={imagesExtended[0].image.asset.fluid} />
                    </styled.ImageWrapper>
                  )}
                  {get(imagesExtended, '[1].image.asset.fluid') && (
                    <styled.ImageWrapper>
                      <Image edge={0} fluid={imagesExtended[1].image.asset.fluid} />
                    </styled.ImageWrapper>
                  )}
                </styled.ImagesWrapper>
              )}
              <styled.Content>
                <Heading levels={[500, 300]}>{chatHeading}</Heading>
                <styled.ChatLink
                  onClick={() => {
                    chatFathomGoalId && useGoal(chatFathomGoalId, true)();

                    $crisp.push(['do', 'chat:show']);
                    $crisp.push(['do', 'chat:open']);
                    $crisp.push([
                      'on',
                      'chat:closed',
                      () => {
                        $crisp.push(['do', 'chat:hide']);
                      },
                    ]);
                  }}
                >
                  {intl.formatMessage({ id: 'chatWithUs' })}
                </styled.ChatLink>
              </styled.Content>
            </styled.Chat>
          </Container>
          <styled.WavyBorder>
            <svg
              width='1062'
              height='16'
              viewBox='0 0 1062 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M2 8.00007C2 8.00007 450 22.2572 682 8.00007C914 -6.25704 1060 8.00007 1060 8.00007'
                stroke={colors.B100}
                strokeWidth='3'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </styled.WavyBorder>
        </>
      )}
      <styled.Body>
        <styled.LogoWrapper>
          <Logo />
        </styled.LogoWrapper>
        <styled.Description>
          <styled.About>{description}</styled.About>
        </styled.Description>
        <styled.Nav>
          <div>
            <styled.LinkList>
              {(primaryLinks || []).map((link) => (
                <styled.Link key={link.title} to={get(link, 'slug.current')}>
                  {link.title}
                </styled.Link>
              ))}
            </styled.LinkList>
          </div>
          <div>
            <styled.SocialList>
              <SocialLink
                type='facebook'
                url='https://www.facebook.com/MissHallsSchool'
              />
              <SocialLink
                type='twitter'
                url='https://twitter.com/misshallsschool'
              />
              <SocialLink
                type='instagram'
                url='https://www.instagram.com/misshallsschool/'
              />
              <SocialLink
                type='youtube'
                url='https://www.youtube.com/user/misshallsschool'
              />
            </styled.SocialList>
          </div>
        </styled.Nav>
      </styled.Body>
      <div>
        <styled.FooterWrapper>
          <p>{copyright}</p>
          <styled.FooterNav>
            {(secondaryLinks || []).map((link) => (
              <styled.FooterNavItem key={link.title}>
                <Link to={get(link, 'slug.current')}>{link.title}</Link>
              </styled.FooterNavItem>
            ))}
          </styled.FooterNav>
        </styled.FooterWrapper>
      </div>
      {enquireLink.isHidden ? null :
        <styled.Enquire colorTheme={enquireLink.theme}>
          <Link to={get(enquireLink.Link, 'slug.current')}>
            <styled.About>{enquireLink.title.en}</styled.About>
          </Link>
        </styled.Enquire>}
    </styled.Wrapper>
  );
}

export default Footer;
