import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby-plugin-react-intl';
import styled from 'styled-components';
import get from 'lodash/get';

import colors from '@utils/colors';
import fonts, { weights } from '@utils/fonts';

const Wrapper = styled.div`
  font-family: ${props => fonts(props.theme.locale).SANS};
  font-weight: ${(props) => (props.primary ? weights.BOLD : weights.NORMAL)};
  cursor: pointer;
  white-space: nowrap;
  padding-right: ${(props) => (props.primary ? '20px' : '24px')};
  padding-left: ${(props) => (props.primary ? '20px' : '24px')};

  & svg {
    vertical-align: middle;
  }

  & > a {
    padding: 8px 0;

    &.active {
      border-bottom: 2px solid ${(props) => getBorderColor(props.colorTheme)};
    }
  }
`;

const NavItem = React.forwardRef((props, ref) => {
  const { children, onMouseEnter, primary, route, colorTheme } = props;

  return (
    <Wrapper
      onMouseEnter={onMouseEnter}
      primary={primary}
      ref={ref}
      colorTheme={colorTheme}
    >
      {children || (
        <Link
          to={get(route, 'slug.current')}
          activeClassName="active"
          partiallyActive
        >
          {get(route, 'title')}
        </Link>
      )}
    </Wrapper>
  );
});

NavItem.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  items: PropTypes.array,
  onMouseEnter: PropTypes.func,
  primary: PropTypes.bool,
  title: PropTypes.string,
};

export default NavItem;

function getBorderColor(colorTheme) {
  switch (colorTheme) {
    case 'yellow':
      return colors.B200;
    case 'green':
      return colors.G200;
    case 'orange':
      return colors.O200;
    case 'purple':
      return colors.P200;
    default:
      return colors.B200;
  }
}
