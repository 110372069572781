import React, { createRef, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import get from 'lodash/get';

import colors from '@utils/colors';
import NavItem from './NavItem';
import NavSection from './NavSection';

const Content = styled.div`
  position: absolute;
  top: 48px;
  right: 0;
  left: 0;
  min-width: 400px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 8px;
  border: 3px solid ${(props) => getThemeStyles(props.colorTheme).border};
`;

const NavSectionWrapper = styled(motion.div)`
  position: absolute;
  width: 100%;
`;

const Popover = styled(motion.div)`
  position: absolute;
  top: 24px;
  right: -24px;
  left: -24px;
  padding-top: 48px;
  pointer-events: ${(props) => (props.active ? 'auto' : 'none')};

  &::after,
  &::before {
    display: inline-block;
    position: absolute;
    left: 22px;
    bottom: calc(100% - 51px);
    content: '';
    height: 0;
    width: 0;
    border: solid transparent;
    pointer-events: none;
    transform: translateX(${(props) => props.pointerPos}px);
    transition-property: transform;
    transition-timing-function: ease-in-out;
    transition-duration: ${(props) =>
      props.prevActiveIndex === 'none' ? 0 : '200ms'};
  }

  &::after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 17px;
    margin-left: -17px;
  }

  &::before {
    border-color: rgba(245, 240, 246, 0);
    border-bottom-color: ${(props) => getThemeStyles(props.colorTheme).border};
    border-width: 22px;
    margin-left: -22px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const variants = {
  enter: ({ activeIndex, prevActiveIndex }) => {
    let initial = 0;
    if (prevActiveIndex !== 'none' && prevActiveIndex > activeIndex) {
      initial = '-100%';
    } else if (prevActiveIndex !== 'none' && prevActiveIndex < activeIndex) {
      initial = '100%';
    }

    return {
      x: initial,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: ({ activeIndex, prevActiveIndex }) => {
    let final = 0;
    if (prevActiveIndex !== 'none' && prevActiveIndex > activeIndex) {
      final = '100%';
    } else if (prevActiveIndex !== 'none' && prevActiveIndex < activeIndex) {
      final = '-100%';
    }

    return {
      zIndex: 0,
      x: final,
      opacity: 0,
    };
  },
};

function NavItems({ children, items, primary }) {
  const itemRefs = Array(items.length)
    .fill()
    .map((_, i) => createRef());
  const popoverRef = useRef(null);

  const [activeItem, setActiveItem] = useState('none');
  const [prevActiveItem, setPrevActiveItem] = useState('none');
  const [pointerPos, setPointerPos] = useState(0);
  const [popoverOffset, setPopoverOffset] = useState(0);

  const handleMouseEnter = (idx) => {
    setPrevActiveItem(activeItem);
    setActiveItem(idx);
  };

  const handleMouseLeave = () => {
    setActiveItem('none');
  };

  const handlePointerPosition = (idx) => {
    const el = itemRefs[idx].current;

    if (el) {
      setPointerPos(el.offsetLeft + el.clientWidth / 2);
    }

    if (get(popoverRef, 'current')) {
      const { x, width } = popoverRef.current.getBoundingClientRect();
      const offsetLeft = x - parseInt(popoverRef.current.style.marginLeft, 10);

      let offset = 0;
      if (offsetLeft < 24) {
        offset = 24 - offsetLeft;
      } else if (offsetLeft + width > window.innerWidth) {
        offset = window.innerWidth - (offsetLeft + width) - 24;
      }

      setPopoverOffset(offset);
    }
  };

  return (
    <Wrapper onMouseLeave={handleMouseLeave}>
      {items.map((item, idx) => (
        <NavItem
          key={idx}
          {...item}
          primary={primary}
          onMouseEnter={() => {
            if (
              item.forceDescriptionDisplay ||
              (Array.isArray(get(items[idx], 'childRoutes')) &&
              items[idx].childRoutes.length > 0)
            ) {
              handleMouseEnter(idx);
              handlePointerPosition(idx);
            } else {
              handleMouseLeave();
            }
          }}
          ref={itemRefs[idx]}
        />
      ))}
      <Popover
        active={activeItem !== 'none'}
        animate={activeItem !== 'none' ? 'open' : 'closed'}
        prevActiveIndex={prevActiveItem}
        initial={false}
        variants={{
          open: {
            opacity: 1,
            transition: { type: 'tween', ease: 'linear', duration: 0.15 },
          },
          closed: {
            opacity: 0,
            transition: { type: 'tween', ease: 'linear', duration: 0.15 },
          },
        }}
        pointerPos={pointerPos}
        colorTheme={get(items, `${activeItem}.colorTheme`, 'blue')}
      >
        <Content
          ref={popoverRef}
          style={{
            marginLeft: `${popoverOffset}px`,
          }}
          onClick={handleMouseLeave}
          colorTheme={get(items, `${activeItem}.colorTheme`, 'blue')}
        >
          <AnimatePresence
            initial={false}
            custom={{
              activeIndex: activeItem,
              prevActiveIndex: prevActiveItem,
            }}
          >
            {activeItem !== 'none' && (
              <NavSectionWrapper
                key={activeItem}
                animate="center"
                custom={{
                  activeIndex: activeItem,
                  prevActiveIndex: prevActiveItem,
                }}
                exit="exit"
                initial="enter"
                layoutTransition={false}
                transition={{
                  x: { type: 'tween', duration: 0.25 },
                  opacity: { duration: 0.25 },
                }}
                variants={variants}
              >
                <NavSection {...items[activeItem]} />
              </NavSectionWrapper>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {activeItem !== 'none' && (
              <NavSection {...items[activeItem]} isHidden />
            )}
          </AnimatePresence>
        </Content>
      </Popover>
    </Wrapper>
  );
}

NavItems.propTypes = {
  children: PropTypes.node,
  items: PropTypes.array.isRequired,
  primary: PropTypes.bool,
};

export default NavItems;

function getThemeStyles(colorTheme) {
  switch (colorTheme) {
    case 'blue':
      return {
        border: colors.B100,
        text: colors.B500,
      };
    case 'green':
      return {
        border: colors.G100,
        text: colors.G500,
      };
    case 'orange':
      return {
        border: colors.O100,
        text: colors.O500,
      };
    case 'purple':
      return {
        border: colors.P100,
        text: colors.P500,
      };
    default:
      return {
        border: colors.B100,
        text: colors.B500,
      };
  }
}
