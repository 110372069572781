import React from 'react';
import { Link } from 'gatsby-plugin-react-intl';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from '@utils/localize';
import get from 'lodash/get';

import colors from '@utils/colors';
import fonts from '@utils/fonts';
import getLocalizedData from '@utils/localize';
import { H_PADDING_MOBILE } from '@utils/space';
import LanguageSelector from '@components/LanguageSelector/mobile';
import SocialLink from '@components/SocialLink';

const LinkList = styled.div`
  column-count: 2;

  & a {
    display: block;
  }
`;

const SocialList = styled.div`
  display: flex;
  margin-top: 32px;
`;

const Wrapper = styled(motion.div)`
  font-family: ${props => fonts(props.theme.locale).SANS};
  padding: ${H_PADDING_MOBILE};
  background-color: ${colors.N0};
`;

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

function Footer({ closeMenu }) {
  const rawData = useStaticQuery(graphql`
    query MobileFooterQuery {
      sanityFooter {
        primaryLinks {
          title {
            en
            vi
            zh_Hans
            _type
          }
          slug {
            current
          }
        }
      }
    }
  `);
  const intl = useIntl();
  const data = getLocalizedData(rawData, intl.locale);

  return (
    <Wrapper variants={variants}>
      <LanguageSelector onSelect={closeMenu} />
      <LinkList>
        {(get(data, 'sanityFooter.primaryLinks') || []).map((link) => (
          <Link key={link.title} to={get(link, 'slug.current')}>
            {link.title}
          </Link>
        ))}
      </LinkList>
      <SocialList>
        <SocialLink
          type="facebook"
          url="https://www.facebook.com/MissHallsSchool"
        />
        <SocialLink
          type="twitter"
          url="https://twitter.com/misshallsschool"
        />
        <SocialLink
          type="instagram"
          url="https://www.instagram.com/misshallsschool/"
        />
        <SocialLink
          type="youtube"
          url="https://www.youtube.com/user/misshallsschool"
        />
      </SocialList>
    </Wrapper>
  );
}

export default Footer;
